import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private storage: Storage, private router: Router) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean
  {
    return this.storage.get("login").then(val =>{
      if(!val){
        this.router.navigateByUrl("/login")
        return false;
      }
      else{
        return true;
      }
    });
  }

  //Can activate child
  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this.storage.get("login").then(val =>{
      if(!val){
        this.router.navigateByUrl("/login")
        return false;
      }
      else{
        return true;
      }
    });
  }
}
